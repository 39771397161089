<!--
 * @Description: 
 * @Author: wangying
 * @Date: 2021-09-28 19:21:03
-->
<template>
  <div class="greenchain ptn_r">
    <Content />
  </div>
</template>
<script>
import Content from './content'
export default {
  components: { Content },
  data() {
    return {
      flagNam: 10,
      tatleName: '参与指南'
    }
  },
  mounted() {
    this.flagNam = this.$route.meta.flagNam
    this.add(this.flagNam)

    this.hub.$on('tatleNames', () => {
      console.log(this.$route.meta.flagNam);
      // console.log(this.$route,12487);
      this.flagNam = this.$route.meta.flagNam
      // console.log(this.flagNam,this.$route.meta.flagNam);
      this.add(this.flagNam)
    })
  },
  methods: {

    add(val) {
      console.log(val, '----', this.flagNam);
      this.flagNam = val
      // this.tatleName =
      switch (val) {
        case 10:
          this.tatleName = '参与指南';
          if (this.$route.name != 'Guide') {

            this.$router.push({ name: 'Guide' })
          }
          break;
        case 11:
          this.tatleName = '各品类联系人';
          if (this.$route.name != 'TheContact') {

            this.$router.push({ name: 'TheContact' })
          }

          break;

        case 112:
          this.tatleName = '白、绿名单申报';
          if (this.$route.name != 'declare') {

            this.$router.push({ name: 'declare' })
          }

          break;
        case 113:
          this.tatleName = '白名单申报';
          if (this.$route.name != 'whitelist') {

            this.$router.push({ name: 'whitelist' })
          }

          break;


      }
    }
  }
}
</script>
<style lang="less" scoped>
.ptn_r {
  position: relative;
}

.flex {
  display: flex;
}

.greenchain {
  min-height: 500px;
  overflow: hidden;
}

.horizontal {
  position: absolute;
  left: -200px;
  width: 540px;
  margin-top: 246px;
  border-top: 1px solid #666;
}

.horizontal_2 {
  position: absolute;
  top: 240px;
  left: 460px;
  width: 40000px;
  border-top: 5px solid #8fa926;

  p {
    position: absolute;
    top: -50px;
    border-left: 4px solid #8fa926;
    margin-left: 2px;
    padding-left: 20px;
    font-size: 18px;
  }
}

.about {
  position: absolute;
  top: 120px;
  left: 40px;

  // width: 240px;
  div:nth-of-type(1) {
    font-size: 35px;
    font-weight: 700;
    color: #8fa926;
  }

  div:nth-of-type(2) {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
  }
}

.grnin {
  padding-top: 320px;
  margin-left: 60px;
  width: 280px;

  .grnin_1 {
    // justify-content: center;
    // padding-left: 20px;
    height: 60px;
    line-height: 60px;
    font-size: 20px;

    p {
      width: 280px;
      text-align: center;
      //   left: -30px;
    }
  }
}

.green_color {
  color: #8fa926;
}

.green_bg {
  background: #8fa926;
  color: #fff;
}

.TheContainer {
  padding: 247px 0 0 120px;
  flex: 1;

  .wh {
    width: 100%;
  }
}</style>